import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { DEFAULT_PRODUCT_CONFIG } from 'commons/constants';
import { ReplenishTaskStatusEnum, ReplenishType } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import {
  ReplenishBinRequestedWithMaxBinQuantity,
  ReplenishItemDestination,
  ReplenishItemProps,
  ReplenishTask,
} from 'commons/types';
import {
  getReplenishTaskBySkuCode,
  postRemoveDestinationBatch,
  postReplenishPutDestinationBin,
} from 'redux-stores/actions';
import { selectUser } from 'redux-stores/features/authSlice';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { ReplenishItemForm } from './components';
import { ReplenishTaskStyle as S } from './ReplenishTask.style';
import {
  destinationRecommendationConfig,
  destinationSourceConfig,
  destinationSummaryConfig,
} from './table-configs';
import { IDestinationSummary } from './types';

export const ReplenishTaskDestinationPage: React.FC = () => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathSkuId = pathname.split('/').reverse()[0];
  const dispatch = useDispatch<AppDispatch>();
  const {
    replenish_tasks: replenishTasks,
    replenish_sku_list: replenishSkuList,
  } = useSelector((state: RootReducerInterface) => state.replenishTask);

  useEffect(() => {
    dispatch(
      getReplenishTaskBySkuCode({
        skuCode: pathSkuId,
        updateDestinationRecommendation: true,
        navigate,
      }),
    );
  }, [dispatch, navigate, pathSkuId]);

  const replenishTask: ReplenishTask = useMemo(() => {
    const emptyReplenishTask: ReplenishTask = {
      /* fallback value, normally should not be assigned */
      sku_code: pathSkuId,
      source_bin_recommendations: [],
      source_bins: [],
      destination_bin_requested: [],
      destination_bins: [],
      quantity: 0,
      status: ReplenishTaskStatusEnum.REPLENISHING,
      type: ReplenishType.PICKER_REQUEST,
      replenish_number: '',
      retail_qty: 0,
      warehouse_id: 0,
      requestor: null,
      replenish_user: null,
      queued_at: null,
      assigned_at: null,
      replenished_at: null,
      completed_at: null,
    };
    return replenishTasks[pathSkuId] || emptyReplenishTask;
  }, [pathSkuId, replenishTasks]);

  const [replenishedQty, setReplenishedQty] = useState(0);
  useEffect(() => {
    const totalReplenishedQty = replenishTask.destination_bins.reduce(
      (total, dest) => total + dest.quantity,
      0,
    );
    setReplenishedQty(totalReplenishedQty);
  }, [replenishTask.destination_bins]);

  const [skuIndex, setSkuIndex] = useState(0);
  useEffect(() => {
    setSkuIndex(replenishSkuList.findIndex((sku) => sku === pathSkuId));
  }, [pathSkuId, replenishSkuList]);

  const [destinations, setDestinations] = useState<
    Partial<ReplenishItemDestination>[]
  >(structuredClone(replenishTask.destination_bins));
  useEffect(() => {
    if (
      replenishTask.destination_bins &&
      replenishTask.destination_bins.length > 0
    ) {
      setDestinations(structuredClone(replenishTask.destination_bins));
    } else {
      setDestinations([{}]);
    }
  }, [replenishTask.destination_bins]);

  const handleAddBatch = (): void => {
    const newDestinations = structuredClone(destinations);
    newDestinations.push({});
    setDestinations(newDestinations);
  };

  const debouncePostReplenishReplaceBatch = useDebouncedCallback(
    (skuCode: string, replacedDestination: ReplenishItemProps) => {
      postRemoveDestinationBatch(
        {
          skuCode,
          removedBin: {
            bin: replacedDestination.bin,
            inventory_number: replacedDestination.inventory_number,
          },
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const handleRemoveBatch = (): void => {
    const newDestinations = structuredClone(destinations);
    const removedDestination = newDestinations.pop();

    if (
      removedDestination &&
      removedDestination.bin &&
      removedDestination.inventory_number
    ) {
      postRemoveDestinationBatch(
        {
          skuCode: replenishTask.sku_code,
          removedBin: {
            bin: removedDestination.bin,
            inventory_number: removedDestination.inventory_number,
          },
        },
        dispatch,
      );
    }
    const totalReplenishedQty = newDestinations.reduce(
      (total, dest) => total + (dest.quantity || 0),
      0,
    );

    setDestinations(newDestinations);
    setReplenishedQty(totalReplenishedQty);
  };

  const handleReplaceBatch = (
    index: number,
    newItem: Partial<ReplenishItemProps>,
  ): Partial<ReplenishItemProps> => {
    const newDestinations = structuredClone(destinations);
    const replacedDestination = newDestinations[index];

    if (
      replacedDestination &&
      replacedDestination.bin &&
      replacedDestination.inventory_number &&
      replacedDestination.quantity
    ) {
      debouncePostReplenishReplaceBatch(
        replenishTask.sku_code,
        replacedDestination as ReplenishItemProps,
      );
    }

    newDestinations[index] = newItem;

    const totalReplenishedQty = newDestinations.reduce(
      (total, dest) => total + (dest.quantity || 0),
      0,
    );
    setDestinations(newDestinations);
    setReplenishedQty(totalReplenishedQty);
    return newItem;
  };

  const sourceBatchQuantity = useMemo(() => {
    return replenishTask.source_bins.reduce<
      Record<string, { quantity: number; expiry_date: Date }>
    >((result, bin) => {
      if (result[bin.inventory_number]) {
        return {
          ...result,
          [bin.inventory_number]: {
            ...result[bin.inventory_number],
            quantity: result[bin.inventory_number].quantity + bin.quantity,
          },
        };
      }
      return {
        ...result,
        [bin.inventory_number]: {
          quantity: bin.quantity,
          expiry_date: new Date(bin.expiry_date),
        },
      };
    }, {});
  }, [replenishTask.source_bins]);

  const debouncePostReplenishPutDestinationBin = useDebouncedCallback(
    (destinationBin: ReplenishItemDestination) => {
      const skuCode = replenishTask.sku_code;
      postReplenishPutDestinationBin({ skuCode, destinationBin }, dispatch);
    },
    500,
    { maxWait: 2000 },
  );

  const debounceValidateInventoryNumber = useDebouncedCallback(
    (params: { inventoryNumber: string; index: number; bin?: string }) => {
      const validInventoryNumber = Object.keys(sourceBatchQuantity);
      if (!validInventoryNumber.includes(params.inventoryNumber)) {
        const newDestination = {
          bin: params.bin,
          inventory_number: params.inventoryNumber,
          expiry_date: undefined,
          quantity: 0,
        };
        const newDestinations = structuredClone(destinations);
        newDestinations[params.index] = newDestination;
        setDestinations(newDestinations);
        dispatch(
          snackbarSetData({
            open: true,
            message:
              'Invalid Inventory Number: Did not exist in picked source bins.',
            color: PRIMARY_RED,
          }),
        );
      }
    },
    500,
    { maxWait: 2000 },
  );

  const debounceValidateBatchQuantity = useDebouncedCallback(
    (params: { inventoryNumber: string; quantity: number }) => {
      const currentSourceBatch = sourceBatchQuantity[params.inventoryNumber];
      if (
        !currentSourceBatch ||
        currentSourceBatch.quantity < params.quantity
      ) {
        dispatch(
          snackbarSetData({
            open: true,
            message:
              'Invalid Quantity: Quantity replenish is greater than quantity picked from source.',
            color: PRIMARY_RED,
          }),
        );
      }
    },
    500,
    { maxWait: 2000 },
  );

  const handleOnChange = async (
    index: number,
    newItem: Partial<ReplenishItemProps>,
  ): Promise<Partial<ReplenishItemProps> | undefined> => {
    const currentDestination = destinations[index];
    if (!currentDestination) {
      return undefined;
    }
    const {
      bin,
      inventory_number: inventoryNumber,
      quantity,
      expiry_date: expiryDate,
    } = newItem;
    if (
      bin === currentDestination.bin &&
      inventoryNumber === currentDestination.inventory_number &&
      quantity === currentDestination.quantity
    ) {
      return undefined;
    }
    if (
      inventoryNumber &&
      quantity &&
      quantity !== currentDestination.quantity
    ) {
      debounceValidateBatchQuantity({ inventoryNumber, quantity });
      const totalReplenishedQty = destinations.reduce(
        (total, dest, i) =>
          total + (i === index ? quantity : dest.quantity || 0),
        0,
      );
      setReplenishedQty(totalReplenishedQty);
    }
    if (
      inventoryNumber &&
      inventoryNumber !== currentDestination.inventory_number
    ) {
      debounceValidateInventoryNumber({ inventoryNumber, bin, index });
      if (!expiryDate) {
        const currentSourceBatch = sourceBatchQuantity[inventoryNumber];
        const newExpiryDate = currentSourceBatch?.expiry_date;
        if (newExpiryDate) {
          const newDestination = {
            bin: newItem.bin,
            inventory_number: newItem.inventory_number,
            expiry_date: newExpiryDate,
            quantity: newItem.quantity,
          };
          const newDestinations = structuredClone(destinations);
          newDestinations[index] = newDestination;
          setDestinations(newDestinations);
          return newDestination;
        }
      }
    }

    return undefined;
  };

  const handleUpdateDestination = (
    index: number,
    newDestination: ReplenishItemProps,
  ): void => {
    const {
      bin,
      inventory_number: inventoryNumber,
      expiry_date: expiryDate,
      quantity,
    } = newDestination;
    const currentDestination = destinations[index];
    if (!currentDestination) {
      return;
    }
    if (
      bin === currentDestination.bin &&
      inventoryNumber === currentDestination.inventory_number &&
      currentDestination.expiry_date &&
      DateTime.fromJSDate(new Date(expiryDate)).hasSame(
        DateTime.fromJSDate(new Date(currentDestination.expiry_date)),
        'day',
      ) &&
      quantity === currentDestination.quantity
    ) {
      /* same data / no need to update to backend */
      return;
    }
    if (bin && inventoryNumber && expiryDate && quantity) {
      const newDestinations = structuredClone(destinations);
      newDestinations[index] = newDestination;
      setDestinations(newDestinations);

      const isRevokedBin = !!replenishTask.source_bins.find(
        (source) => source.inventory_number === inventoryNumber,
      );

      debouncePostReplenishPutDestinationBin({
        ...newDestination,
        is_revoked_bin: isRevokedBin,
      });
    }
  };

  const handleBackToSkuListPage = (): void => {
    navigate('/replenish-list/destination');
  };

  const [nextSku, setNextSku] = useState<string | null>(null);
  useEffect(() => {
    const nextSkuIndex = skuIndex + 1;
    if (
      nextSkuIndex >= 0 &&
      nextSkuIndex < replenishSkuList.length &&
      replenishSkuList[nextSkuIndex]
    ) {
      setNextSku(replenishSkuList[nextSkuIndex]);
    } else {
      setNextSku(null);
    }
  }, [replenishSkuList, skuIndex]);
  const handleGoToNextSku = (): void => {
    if (nextSku) {
      navigate(`/replenish-task/destination/${nextSku}`);
    }
  };

  const replenishBinRequested: ReplenishBinRequestedWithMaxBinQuantity[] =
    useMemo(() => {
      const maxQuantity =
        replenishTask.product?.quantity_threshold?.[user.warehouseId]
          ?.max_bin_qty || DEFAULT_PRODUCT_CONFIG.max_bin_qty;
      return replenishTask.destination_bin_requested.map((requested) => ({
        ...requested,
        max_bin_qty: maxQuantity,
      }));
    }, [
      replenishTask.destination_bin_requested,
      replenishTask.product?.quantity_threshold,
      user.warehouseId,
    ]);

  const destinationSummaryData: IDestinationSummary = useMemo(() => {
    const quantityPicked = replenishTask.source_bins.reduce(
      (total, source) => total + source.quantity,
      0,
    );
    const result: IDestinationSummary = {
      total_requested_quantity: replenishTask.quantity,
      source_picked_quantity: quantityPicked,
      replenished_quantity: replenishedQty,
    };
    return result;
  }, [replenishTask.quantity, replenishTask.source_bins, replenishedQty]);

  return (
    <>
      {/* Header */}
      <Header
        title="Replenish Task | Destination"
        prevPageHandler={handleBackToSkuListPage}
      />

      {/* Body */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            {pathSkuId}
          </S.Subtitle>
          <S.SubHeaderText>
            {replenishTask.product?.name || '-'}
          </S.SubHeaderText>
          <TableComponent
            data={destinationSummaryData ? [destinationSummaryData] : []}
            config={destinationSummaryConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.SubtitleWrapper>

        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Source Bin</S.SubHeaderText>
          </Grid>
          {/* bin sources */}
          <TableComponent
            data={replenishTask.source_bins || []}
            config={destinationSourceConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>

        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Destination Recommendation</S.SubHeaderText>
          </Grid>
          {/* bin recommendation */}
          <TableComponent
            data={replenishBinRequested || []}
            config={destinationRecommendationConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>
        {/* main forms */}
        {destinations.map((destination, index) => (
          <ReplenishItemForm
            index={index}
            item={destination}
            type="replenish"
            maxQuantityToAdd={
              replenishTask.source_bins.reduce(
                (total, source) => total + source.quantity,
                0,
              ) -
              destinations.reduce(
                (total, d) =>
                  d.quantity && d.bin !== destination.bin
                    ? total + d.quantity
                    : total,
                0,
              )
            }
            onAllFilled={handleUpdateDestination}
            onChanged={handleOnChange}
            onReplace={handleReplaceBatch}
          />
        ))}
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <S.RedButtonText
              onClick={handleRemoveBatch}
              disabled={destinations.length === 1}
            >
              REMOVE BATCH
            </S.RedButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <S.BlueButtonText onClick={handleAddBatch}>
              <Add /> ADD BATCH
            </S.BlueButtonText>
          </Grid>
        </Grid>
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={handleBackToSkuListPage}>
              BACK TO SKU LIST
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton onClick={handleGoToNextSku} disabled={!nextSku}>
              NEXT SKU
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
