import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Brightness1, WarningAmber } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { DEFAULT_PRODUCT_CONFIG } from 'commons/constants';
import { ReplenishType } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import {
  PickingBySKUsDetails,
  PickingListBySKUs,
} from 'commons/types/pickingList.interface';
import {
  getPickTaskBySKU,
  requestReplenish,
  validatePickingList,
} from 'redux-stores/actions';
import { selectUser } from 'redux-stores/features/authSlice';
import { RootReducerInterface } from 'redux-stores/reducers';
import { updateSKUList } from 'redux-stores/reducers/pickingTaskReducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { IRequestReplenishDTO } from 'services/apis/SwipeRxWmsApiReplenishList/types';

import S from './PickingListPage.styles';
import { config } from './pickingListTable.config';

const PickingListPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isProceedButtonDisabled, setProceedButtonDisabled] = useState(false);

  const user = useSelector(selectUser);
  const { tasksBySKUs, skuLowStockTriggered } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );

  useEffect(() => {
    const updatePickTaskBySKU = async (): Promise<void> => {
      dispatch(getPickTaskBySKU());
    };

    updatePickTaskBySKU();
  }, [dispatch]);

  const [filter, setFilter] = useState<string>('');
  const [displayedTasks, setDisplayedTasks] =
    useState<PickingListBySKUs[]>(tasksBySKUs);
  const [displayedIndex, setDisplayedIndex] = useState<number[]>([]);

  useEffect(() => {
    setDisplayedTasks(tasksBySKUs);
    setDisplayedIndex(tasksBySKUs.map((_, idx) => idx));
    setProceedButtonDisabled(
      validatePickingList(tasksBySKUs).validation === 'error',
    );
  }, [tasksBySKUs]);

  useEffect(() => {
    const skuList = tasksBySKUs.map((task) => task.sku_code);
    dispatch(updateSKUList(skuList));
  }, [dispatch, tasksBySKUs]);

  const onFilterChange = (value: string): void => {
    setFilter(value);
    if (value === '') {
      setDisplayedTasks(tasksBySKUs);
      setDisplayedIndex(tasksBySKUs.map((_, idx) => idx));
    } else {
      const filteredIdx: number[] = [];
      const filteredTasks = tasksBySKUs.filter((task, idx) => {
        if (task.sku_code.includes(value)) {
          filteredIdx.push(idx);
          return true;
        }
        return false;
      });
      setDisplayedTasks(filteredTasks);
      setDisplayedIndex(filteredIdx);
    }
  };

  const cleanUpTasksBySKU = (): void => {
    tasksBySKUs.forEach((task) => {
      task.details.forEach((detail) => {
        let emptyPick: number;
        do {
          emptyPick = -1;
          emptyPick = detail.picked_actual.findIndex(
            (actual) => actual.quantity <= 0,
          );
          if (emptyPick >= 0) {
            detail.picked_actual.splice(emptyPick, 1);
          }
        } while (emptyPick >= 0);
      });
    });
  };

  const goToSummaryPage = (): void => {
    const validationStatus = validatePickingList(tasksBySKUs);
    if (!validationStatus.validation) {
      dispatch(
        snackbarSetData({
          open: true,
          message: validationStatus.message,
          color: PRIMARY_RED,
        }),
      );
      return;
    }

    cleanUpTasksBySKU();
    navigate('/pick-summary');
  };

  const renderList = (pickingList: PickingListBySKUs[]) =>
    pickingList.map((data, idx) => {
      const pickedProgress = data.details.reduce(
        (sum, detail) =>
          sum +
          detail.picked_actual.reduce(
            (sum2, actual) => sum2 + actual.quantity,
            0,
          ),
        0,
      );
      const colorProgress =
        pickedProgress === 0
          ? S.colors.PROGRESS_ICON_COLOR[0]
          : pickedProgress === data.total_quantity
          ? S.colors.PROGRESS_ICON_COLOR[1]
          : S.colors.PROGRESS_ICON_COLOR[2];
      const skuButtonText = pickedProgress === 0 ? 'PICK SKU' : 'EDIT SKU';

      const retailRecommendationBins = data.pick_recommendation.filter(
        (rec) => rec.bin_type === 'retail',
      );

      const recommendationTotalQty = data.pick_recommendation.reduce(
        (total, rec) =>
          rec.bin_type === 'retail' ? total + rec.quantity : total,
        0,
      );
      const isStockOut =
        !skuLowStockTriggered[data.sku_code] &&
        pickedProgress < data.total_quantity
          ? recommendationTotalQty < data.total_quantity
          : false;

      const onRequestStocks = async () => {
        const payload: IRequestReplenishDTO = {
          sku_code: data.sku_code,
          type: ReplenishType.PICKER_REQUEST,
        };

        if (retailRecommendationBins.length > 0) {
          // get the least quantity from recommended bin
          const leastQtyIdx = retailRecommendationBins.reduce(
            (selectedIdx, rec, rIdx) => {
              if (rec.quantity < retailRecommendationBins[selectedIdx].quantity)
                return rIdx;
              return selectedIdx;
            },
            0,
          );
          const leastRecommendationBinQty =
            data.pick_recommendation[leastQtyIdx];
          const maxQuantity =
            (data.max_bin_qty || DEFAULT_PRODUCT_CONFIG.max_bin_qty) >=
            data.total_quantity
              ? data.max_bin_qty || DEFAULT_PRODUCT_CONFIG.max_bin_qty
              : data.total_quantity;

          payload.destination_bin_requested = [
            {
              bin: leastRecommendationBinQty.bin,
              bin_type: leastRecommendationBinQty.bin_type,
              warehouse_id: user.warehouseId as number,
              requested_quantity:
                maxQuantity - leastRecommendationBinQty.quantity,
            },
          ];
        }
        dispatch(requestReplenish(payload));
      };

      const getTableData = (): (PickingBySKUsDetails & { bin?: string })[] => {
        const result: PickingBySKUsDetails[] = data.details.map((detail) => {
          const bin = data.pick_recommendation[0];
          return { ...detail, bin: bin?.bin || '-' };
        });
        return result;
      };

      return (
        <Grid container>
          <S.SKUGridWrapper container>
            <S.SKUGrid item xs={8}>
              {data.sku_code}
              <S.ProgressSpan>
                <Brightness1 style={{ fontSize: 11, color: colorProgress }} />{' '}
                {pickedProgress}/{data.total_quantity}
              </S.ProgressSpan>
            </S.SKUGrid>
            <S.SKUButtonGrid item xs={4}>
              <S.SecondaryButton
                onClick={(): void =>
                  navigate('/pick-item', {
                    state: { pickIdx: displayedIndex[idx] },
                  })
                }
                disabled={isStockOut}
              >
                {skuButtonText}
              </S.SecondaryButton>
            </S.SKUButtonGrid>
          </S.SKUGridWrapper>
          <S.TableInfoGridWrapper container>
            <S.TableInfoGrid item xs={12}>
              <TableComponent config={config} data={getTableData() || []} />
            </S.TableInfoGrid>
            {isStockOut && (
              <>
                <S.TableInfoGrid
                  className="out-of-stock no-pad-top"
                  item
                  xs={7}
                >
                  <WarningAmber fontSize="small" />
                  This SKU is out of stock.
                </S.TableInfoGrid>
                <S.TableInfoGrid
                  className="no-pad-top"
                  style={{ alignItems: 'end' }}
                  item
                  xs={5}
                >
                  <S.BlueButtonText
                    style={{ padding: 0, float: 'right' }}
                    onClick={onRequestStocks}
                  >
                    REQUEST STOCKS
                  </S.BlueButtonText>
                </S.TableInfoGrid>
              </>
            )}
          </S.TableInfoGridWrapper>
        </Grid>
      );
    });

  return (
    <div>
      <Header
        title="Picking"
        prevPageHandler={() => {
          navigate('/assign-task');
        }}
      />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            Pick List
          </S.Subtitle>
        </S.SubtitleWrapper>
        <S.SearchBar
          placeholder="SKU Number or Scan"
          variant="outlined"
          value={filter}
          onChange={(event) => onFilterChange(event.target.value)}
        />

        {renderList(displayedTasks)}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={isProceedButtonDisabled}
          onClick={goToSummaryPage}
        >
          PROCEED
        </S.PrimaryButton>
      </S.FooterWrapper>
    </div>
  );
};

export default PickingListPage;
